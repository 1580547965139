import React from "react";

import Layout from "../layouts/defaultLayout";
import SEO from "../components/seo";
import { useIntl } from "react-intl";
import Body from "../components/partials/terms/body";

function Terms() {
  const intl = useIntl();

  return (
    <Layout header={false} footer={false}>
      <SEO
        keywords={[
          `Describle`,
          `app`,
          '30',
          'seconds',
          intl.formatMessage({ id: 'meta_game' }),
          'trivia',
          intl.formatMessage({ id: 'meta_questions' }),
          intl.formatMessage({ id: 'meta_answer' }),
          intl.formatMessage({ id: 'meta_five' }),
          intl.formatMessage({ id: 'meta_time' }),
          intl.formatMessage({ id: 'meta_describe' }),
          'terms',
          'conditions'
        ]}
        title="Terms & Conditions - Describle"
        lang={intl.locale}
      />
      <Body/>
    </Layout>
  );
}

export default Terms;
